import { Component, computed, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivationStart, Router, RouterModule } from '@angular/router';
import { filter, map } from 'rxjs';
import { MenuItem } from 'src/app/models/menu-item';
import { SessionService } from 'src/app/services/session.service';
import { Tab } from 'src/app/types/tab';
import { IonTabs, IonTabBar, IonTabButton, IonLabel, IonIcon } from "@ionic/angular/standalone";
import { LocalizationModule } from 'src/app/modules/localization/localization.module';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  standalone: true,
  imports: [IonIcon, IonLabel, IonTabButton, IonTabBar, IonTabs, RouterModule, LocalizationModule]
})
export class TabsComponent {

  sessionService = inject(SessionService);
  router = inject(Router);
  routeData$ = this.router.events.pipe(
    filter(event => event instanceof ActivationStart),
    map(event => (event as ActivationStart).snapshot.data['tab'] as Tab),
    takeUntilDestroyed(),
  );
  routeData = toSignal(this.routeData$);
  Tab = Tab;
  menuItems = computed<MenuItem[]>(() => [
    { label: "Settings", href: '/entity/settings' }
  ]);
}
