import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LocalizationService } from './modules/localization/services/localization.service';
import { AuthService } from './services/auth.service';
import { SessionService } from './services/session.service';
import { AddressService } from './services/address.service';
import { LocalStorageService } from './services/local-storage.service';
import { ToastService } from './services/toast.service';
import { PromoService } from './services/promo.service';
import { SubscriptionService } from './services/subscription.service';
import { ModalService } from './services/modal.service';
import { IonApp, IonContent, IonRouterOutlet, IonBackdrop } from "@ionic/angular/standalone";
import { TabsComponent } from './components/ui/tabs/tabs.component';
import * as icons from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonBackdrop, IonRouterOutlet, IonContent, IonApp, TabsComponent, CommonModule],
  // We will use OnPush in our entire application since we only use signals,
  // Also, our localization pipe may get extremely heavy in conjunction with Change Detection.
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {

  // we initialize some services here to bootstrap important app logic
  localizationService = inject(LocalizationService);
  authService = inject(AuthService);
  sessionService = inject(SessionService);
  addressService = inject(AddressService);
  localStorageService = inject(LocalStorageService);
  toastService = inject(ToastService);
  promoService = inject(PromoService);
  subscriptionService = inject(SubscriptionService);
  modalService = inject(ModalService);

  constructor() {
    addIcons({ ...icons }); // need this to load ion-icons
  }
}
